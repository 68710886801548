<template>
  <section class="profile">
    <div class="container">
      <ul class="breadcrumb">
        <li>
          <router-link to="/">Главная/</router-link>
        </li>
        <li>Мой аккаунт/</li>
        <li>Мои данные</li>
      </ul>

      <h3 class="my-1 greet-mob">
        Здравствуйте, <span>{{ getUserData.firstname }}!</span>
      </h3>

      <div class="profile__nav">
        <div class="data">
          <h3 class="my-5 greet-des">
            Здравствуйте, <span>{{ getUserData.firstname }}!</span>
          </h3>
          <div class="data__info">
            <form class="mydata" @submit.prevent="changeFieldsData">
              <h3 class="my-3">Мои данные:</h3>
              <div>
                <label for="name">Имя:</label>
                <input
                  type="text"
                  v-model="userData.firstname"
                  :class="{ editable: changeData }"
                  :placeholder="userData.firstname"
                  :disabled="!changeData"
                />
              </div>
              <div>
                <label for="name">Фамилия:</label>
                <input
                  type="text"
                  v-model="userData.lastname"
                  :class="{ editable: changeData }"
                  :placeholder="userData.lastname"
                  :disabled="!changeData"
                />
              </div>
              <div>
                <label for="name">Контактный номер:</label>
                <input
                  type="text"
                  v-model="userData.telephone"
                  :class="{ editable: changeData }"
                  :placeholder="userData.telephone"
                  :disabled="!changeData"
                />
              </div>
              <div>
                <label for="name">E-Mail:</label>
                <input
                  type="text"
                  v-model="userData.email"
                  :class="{ editable: changeData }"
                  :placeholder="userData.email"
                  :disabled="!changeData"
                />
              </div>
              <div>
                <label for="name">Password:</label>
                <input
                  type="password"
                  v-model="userData.password"
                  :class="{ editable: changeData }"
                  placeholder="********"
                  :disabled="!changeData"
                />
              </div>
              <button class="my-3">
                {{ changeData ? "Сохранить" : "Изменить" }}
              </button>
            </form>
            <form class="mydata" @submit.prevent="changeFieldsAddress">
              <h3 class="my-3">Данные доставки:</h3>
              <div>
                <label for="name">Страна:</label>
                <input
                  type="text"
                  v-model="userData.address.country"
                  :class="{ editable: changeAddress }"
                  :placeholder="address.country"
                  :disabled="!changeAddress"
                />
              </div>
              <div>
                <label for="name">Город:</label>
                <input
                  type="text"
                  v-model="userData.address.city"
                  :class="{ editable: changeAddress }"
                  :placeholder="address.city"
                  :disabled="!changeAddress"
                />
              </div>
              <div>
                <label for="name">Улица:</label>
                <input
                  type="text"
                  v-model="userData.address.address_1"
                  :class="{ editable: changeAddress }"
                  :placeholder="address.address_1"
                  :disabled="!changeAddress"
                />
              </div>
              <div>
                <label for="name">Дом:</label>
                <input
                  type="text"
                  v-model="userData.address.building"
                  :class="{ editable: changeAddress }"
                  :placeholder="building"
                  :disabled="!changeAddress"
                />
              </div>
              <div>
                <label for="name">Квартира:</label>
                <input
                  type="text"
                  v-model="userData.address.apartment"
                  :class="{ editable: changeAddress }"
                  :placeholder="apartment"
                  :disabled="!changeAddress"
                />
              </div>
              <button class="my-3">
                {{ changeAddress ? "Сохранить" : "Изменить" }}
              </button>
            </form>
            <div class="saving-amount" v-if="userData.customer_group_id == '2'">
              <h3 class="mt-3 mb-4">Вознаградждение</h3>
              <div class="mydata">
                <p>
                  Количество заказов:
                  <span>{{ orders.length + promoOrders.length }}</span>
                </p>
                <p>
                  Сумма вознаграждения: <span>{{ allAmount }}</span>
                </p>
                <button class="my-3" @click="showModal = true">
                  Вывести средства
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Table -->
      <div class="promo-orders" v-if="promoOrders.length">
        <h3 class="my-4 mob">Покупки по промокоду</h3>
        <table class="table">
          <thead>
            <tr>
              <th>Дата заказа</th>
              <th class="w-25">Покупатель</th>
              <th>Скидки</th>
              <th>Сумма</th>
              <th>Итого к оплате</th>
              <th class="w-15">Сумма вознаграждения</th>
              <th>Стасус заказа</th>
            </tr>
          </thead>
          <tbody v-for="order in promoOrders" :key="order.order_id">
            <tr class="green">
              <td>{{ order.date_added }}</td>
              <td>{{ order.name }}</td>
              <td>{{ order.order_total.discount }}%</td>
              <td>{{ order.order_total.sub_total + order.currency.symbol_right }}</td>
              <td>{{ order.order_total.total + order.currency.symbol_right }}</td>
              <td>{{ parseInt(order.summa, 10) + order.currency.symbol_right }}</td>
              <td>{{ order.status }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div id="purchase" class="purchase" v-if="orders.length">
        <h3 class="my-4 mob">Мои покупки</h3>
        <table class="table">
          <thead>
            <tr>
              <th>Дата заказа</th>
              <th>Номер заказа</th>
              <th class="mob">Количество</th>
              <th>Сумма заказа</th>
              <th>Размер скидки</th>
              <th>Итого к оплате</th>
              <th v-if="userData.customer_group_id == '2'">
                Сумма вознаграждения
              </th>
              <th>Стасус заказа</th>
            </tr>
          </thead>

          <tbody v-for="order in orders" :key="order.order_id">
            <tr class="green">
              <td>{{ order.date_added }}</td>
              <td>
                {{ order.order_id }}<br /><span
                  type="button"
                  @click="showDetail(order.order_id)"
                  class="collapsbtn"
                  >{{
                    showItem === order.order_id ? "свернуть" : "развернуть"
                  }}</span
                >
              </td>
              <!-- <td class="mob">
                Marsilan 50 кап. 2шт <br />
                Evanorm box, 1шт
              </td> -->
              <td class="mob">{{ order.products }}</td>
              <td>
                {{ order.order_total.sub_total + order.currency.symbol_right }}
              </td>
              <td>{{ order.order_total.discount }}%</td>
              <td>
                {{ order.order_total.total + order.currency.symbol_right }}
              </td>
              <td v-if="userData.customer_group_id == '2'">
                {{ order.total_ambasador + order.currency.symbol_right }}
              </td>
              <td>{{ order.status }}</td>
            </tr>

            <tr
              class="order-list"
              v-show="showItem === order.order_id"
              v-for="product in orderedProducts"
              :key="product.product_id"
            >
              <td class="space mob"></td>
              <td :colspan="col" class="zak">
                <div class="d-flex flex-row">
                  <img :src="product.image" alt="Снят с производства" />
                  <div class="d-flex flex-column">
                    <p>Артикул: {{ product.sku }}</p>
                    <h4>{{ product.name }}</h4>
                    <p>
                      {{
                        product.description
                          ? product.description
                          : "Товар снят с производства"
                      }}
                    </p>
                    <!-- <p>{{parseInt(product.weight) ? parseInt(product.weight) + "шт." : ""}}</p> -->
                  </div>
                </div>
              </td>
              <td>{{ product.quantity }}</td>
              <td colspan="2">{{ product.price }}</td>
            </tr>

            <tr class="order-list" v-if="showItem === order.order_id">
              <td class="space mob"></td>
              <td colspan="3" class="zak"></td>
              <td colspan="2">
                <button class="w-100" @click="repeatOrder">
                  Повторить заказ
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal-message v-if="showModal">
      <div class="close-modal" @click="showModal = false">&times;</div>
      <form
        class="cashout-form"
        @submit.prevent="toCashout"
        v-if="!withdrawMoney"
      >
        <h3>Укажите данные для вывода средств</h3>
        <input
          type="text"
          v-model.trim="ambasadorData.fio"
          @blur="checkField"
          placeholder="Имя и Фамилия владельца карты"
          required
        />
        <input
          type="text"
          v-model.lazy="ambasadorData.cardNumber"
          placeholder="Номер карты"
          required
        />
        <button>Вывести средства</button>
      </form>
      <h3 class="final-mes" v-else>
        Вы успешно подали заявку на вывод средств на сумму:
        <span>{{ profitAmount + currency }}</span>
      </h3>
    </modal-message>
  </section>
</template>

<script>
import PublicAPI from "@/api/PublicAPI";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      userData: {},
      changeData: false,
      changeAddress: false,
      showItem: null,
      orders: [],
      promoOrders: [],
      col: null,
      orderedProducts: [],
      ambasadorData: {
        fio: "",
        cardNumber: "",
      },
      showModal: false,
      withdrawMoney: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUserData", "isAuthenticated"]),

    address() {
      return this.getUserData["address"] && this.userData.address;
    },

    apartment() {
      return this.userData["address"] && this.address.address_2.split("/-/")[1];
    },

    currency() {
      return this.orders[0].currency.symbol_right;
    },

    building() {
      return this.userData["address"] && this.address.address_2.split("/-/")[0];
    },
    allAmount() {
      if (this.orders.length || this.promoOrders.length) {
        return this.orders.reduce(
          (sum, order) => (sum += parseInt(order.total_ambasador, 10)),
          0
        ) + this.promoOrders.reduce((sum, order) => sum += parseInt(order.summa, 10), 0);
      } else {
        return 0;
      }
    },
    profitAmount() {
      return this.orders.reduce(
        (sum, order) => (sum += order.total_ambasador),
        0
      );
    },
  },
  methods: {
    ...mapActions("auth", ["changeUserData", "changeUserAddress"]),
    ...mapActions("cart", ["addToCart"]),

    async orderHistory() {
      const history = await PublicAPI.getHistoryOrders(
        "rest/order/orders&limit={limit}&page={page}"
      );
      const promo = await PublicAPI.getPromoBenefits("feed/rest_api/getpromos");
      this.promoOrders = promo;
      this.orders = history;
    },

    async showDetail(id) {
      if (!this.showItem) {
        this.showItem = id;
        const detailOrder = await PublicAPI.showOrderDetail(
          `rest/order/orders&id=${id}`
        );
        // const {
        //   data: {
        //     data: { products },
        //   },
        // } = detailOrder;
        this.orderedProducts = detailOrder.products;
      } else {
        this.showItem = null;
      }
    },

    async changeFieldsData() {
      if (this.changeData) {
        if (this.userData.password) {
          await PublicAPI.changePasswordField("rest/account/password", {
            password: this.userData.password,
            confirm: this.userData.password,
          });
        }
        this.changeUserData({
          firstname: this.userData.firstname,
          lastname: this.userData.lastname,
          email: this.userData.email,
          telephone: this.userData.telephone,
        });
      }
      this.changeData = !this.changeData;
    },
    changeFieldsAddress() {
      if (this.changeAddress) {
        this.userData.address.building =
          this.userData.address.building || this.building;
        this.userData.address.apartment =
          this.userData.address.apartment || this.apartment;
        this.userData.address.address_2 =
          this.userData.address.building +
          "/" +
          this.userData.address.apartment;
        this.changeUserAddress({
          address_id: this.userData.address_id,
          country: this.userData.address.country,
          city: this.userData.address.city,
          address_1: this.userData.address.address_1,
          address_2: this.userData.address.address_2,
        });
      }
      this.changeAddress = !this.changeAddress;
    },
    repeatOrder() {
      if (this.orderedProducts.length) {
        this.orderedProducts.map((item) => this.addToCart(item));
        this.$router.push("/cart");
      }
    },
    async toCashout() {
      const request = await PublicAPI.sendAmbassadorCash(
        "feed/rest_api/ambasador",
        {
          name: this.ambasadorData.fio,
          bank_card: this.ambasadorData.cardNumber,
          total: this.allAmount,
        }
      );
      console.log(request);
      this.withdrawMoney = true;
      setTimeout(() => {
        this.ambasadorData = {};
        this.withdrawMoney = false;
        this.showModal = false;
        this.orderHistory();
      }, 3000);
      // console.log(this.ambasadorData.cardNumber.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/g, "$1 $2 $3 $4"))
    },
    checkField() {
      const user = this.ambasadorData.fio.split(" ");
      if (user.length < 3) {
        console.log(
          "Should be First, Second and Middle name, use 1 space beetwen each other"
        );
      } else {
        console.log(user);
      }
    },
    fieldMask(e) {
      let target = e.target.value;
      if (
        target.length == 4 ||
        target.length == 8 ||
        target.length == 12 ||
        target.length == 16
      ) {
        e.target.value = this.ambasadorData.cardNumber.replace(
          /(.)(?=(\d{4})+$)/g,
          "$1 "
        );
        this.ambasadorData.cardNumber = "";
      }
      // const field = this.$refs.cardMask;
      // let value = this.ambasadorData.cardNumber.replace(/(.)(?=(\d{4})+$)/g, "$1 ");
      // field.value = value
      // this.ambasadorData.cardNumber = this.ambasadorData.cardNumber.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/g, "$1 $2 $3 $4");
      // console.log(this.$refs.cardMask.value)
    },
  },
  // For mobile add dynamic attribute colspan
  mounted() {
    if (window.outerWidth <= 768) {
      this.col = 2;
    }
  },
  created() {
    Object.assign(this.userData, this.getUserData);
    this.orderHistory();
  },
  watch: {
    isAuthenticated(val) {
      if (!val) {
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>

<style scoped>
.breadcrumb {
  display: flex;
}
.breadcrumb li {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
}
.breadcrumb li a:hover {
  opacity: 45%;
}

.container {
  max-width: 1068px;
  margin: 0 auto;
  padding: 0 10px;
}

.greet-mob {
  display: none;
}

.profile {
  margin-bottom: 3rem;
}

.profile__nav {
  display: flex;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
}
.side {
  flex: 0 0 25%;
  align-self: flex-start;
}
.data {
  flex: 1 1 75%;
}

.data__info {
  display: flex;
  justify-content: space-between;
}
.mydata {
  display: flex;
  max-width: 280px;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 30%;
  min-height: 200px;
}

.mydata > div {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
}
.side h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;

  color: #75b051;
}
.side h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;

  color: #2b2b2b;
}
.greet-mob,
.data h3,
.promo-orders h3,
.purchase h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;

  color: #000000;
}
.mydata p,
.mydata label,
.mydata input {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0;
  color: #606060;
}
.mydata p span {
  font-weight: bold;
}
.mydata label {
  white-space: nowrap;
  flex: 1 1 40%;
}
.mydata input {
  margin-left: 5px;
  font-weight: bolder;
}
.mydata input:disabled {
  background: #fff;
}
.mydata button {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  color: #ffffff;
  background: #75b051;
  border-radius: 5px;
  padding: 5px 0px;
  width: 100%;
  justify-content: flex-end !important;
}
/* Tables */

/* .promo-table,
.promo-outputs {
  display: flex;
  border: 1px solid #000;
}

.promo-outputs {
  border-top: none;
}

.promo-table .cell,
.promo-outputs .cell {
  width: 25%;
  padding: 12px;
  border-right: 1px solid #000;
}
.promo-table .cell:last-child,
.promo-outputs .cell:last-child {
  border-right: none;
} */
.promo-orders,
.purchase {
  margin-top: 3rem;
  overflow-x: auto;
  width: 100%;
}

.w-25 {
  width: 25%;
}
.w-15 {
  width: 15%;
}

table th,
table td {
  border: 1px solid #75b051;
  box-sizing: border-box;
  padding: 12px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  vertical-align: middle;
}
tbody .green {
  background: rgba(117, 176, 81, 0.3);
}
.collapsbtn {
  font-style: italic;
  font-weight: 500;
  font-size: 9px;
  line-height: 19px;

  color: #7a7a7a;
}
.order-list td {
  border-left: none;
  border-right: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
}
.order-list .zak {
  text-align: left;
  border-left: 1px solid #75b051;
  padding: 12px;
}
.order-list tr {
  height: 120px;
}
.order-list .zak p {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;

  color: #7a7a7a;
}
.order-list .zak img {
  width: 100px;
  height: auto;
  margin-right: 12px;
}
.order-list .zak h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;

  text-transform: uppercase;

  color: #000000;
}
.order-list button {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  color: #ffffff;
  padding: 5px;
  background: #75b051;
  border-radius: 5px;
}

.order-list .space {
  border: none;
}

.list-enter-active {
  animation: show 0.6s linear;
}

.list-leave-active {
  animation: show 0.6s linear reverse;
}

.editable {
  text-decoration: underline;
}

.mob {
  display: table-cell;
}

.cashout-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.cashout-form input {
  padding: 10px;
  border: 1px solid #75b051;
  border-radius: 10px;
}
.cashout-form input::placeholder {
  font-size: 12px;
}

.final-mes,
.cashout-form h3 {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
}
.cashout-form h3 {
  margin-bottom: 20px;
}
.final-mes span {
  color: #75b051;
}

.cashout-form button {
  margin-top: 20px;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .mob,
  .greet-des {
    display: none;
  }
  .greet-mob {
    display: block;
  }
  .profile__nav {
    flex-direction: column;
  }
  .data {
    width: 100%;
    flex-wrap: wrap;
  }
}

@media (max-width: 570px) {
  .container {
    display: flex;
    flex-direction: column;
  }
  .data__info {
    flex-direction: column;
  }
  .profile__nav {
    align-self: flex-start;
  }
}

@media (max-width: 425px) {
  .mydata {
    width: 100%;
  }
  .greet-mob {
    width: 100%;
    text-align: center;
  }
  .profile__nav {
    order: 3;
    margin-bottom: 1rem;
  }
  .purchase {
    margin: 1rem 0;
    order: 2;
  }
  table th,
  table td {
    font-size: 14px;
    padding: 6px;
  }
}

@media (max-width: 320px) {
  table th,
  table td {
    font-size: 10px;
  }
  .mydata label {
    flex: 0 0 30%;
  }

  .mydata > div {
    margin: 10px 0;
  }
}
</style>
